import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../components/hero"

// Page Sections
import AccessibilityText from "./screens/accessibility/description"

const AccessibilityPage = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Accessibility">
        <p> </p>
      </Hero>
      <AccessibilityText />
    </React.Fragment>
  )
}
export default AccessibilityPage


export const Head = () => (
  <Seo
  title="Accessibility"
  description="Accessibility for Visby Medical website"
  image="/meta/accessibility.jpg"
/>
)
